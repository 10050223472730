import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import Vue from 'vue';

// Application
import EditCustomGoalPlanCurrentPlanService
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/application/services/edit-custom-goal-plan-current-plan-service';
import EditCustomGoalPlanInitialAmountService
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/application/services/edit-custom-goal-plan-initial-amount-service';
import EditCustomGoalPlanDefineByService
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/application/services/edit-custom-goal-plan-define-by-service';

// Domain
import { CustomInvestorGoalEntity }
  from '@/modules/flagship/custom-investor-goal/domain/entities/custom-investor-goal-entity';
import { CurrentPlanEntity }
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/domain/entities/current-plan-entity';
import { InitialAmountEntity }
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/domain/entities/initial-amount-entity';
import { DefineByEntity }
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/domain/entities/define-by-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';

export default class EditCustomGoalPlanDialogViewModel {
  @Inject(TYPES.EDIT_CUSTOM_GOAL_CURRENT_PLAN_SERVICE)
  private readonly current_plan_service!: EditCustomGoalPlanCurrentPlanService;

  @Inject(TYPES.EDIT_CUSTOM_GOAL_PLAN_INITIAL_AMOUNT_SERVICE)
  private readonly initial_amount_service!: EditCustomGoalPlanInitialAmountService;

  @Inject(TYPES.EDIT_CUSTOM_GOAL_PLAN_DEFINE_BY_SERVICE)
  private readonly define_by_service!: EditCustomGoalPlanDefineByService;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  private readonly view: Vue;

  readonly STEPS = [
    {
      step: 0,
      component: 'EditCustomGoalCurrentPlan',
    },
    {
      step: 1,
      component: 'EditCustomGoalInitialAmount',
    },
    {
      step: 2,
      component: 'EditCustomDefineBy',
    },
    {
      step: 3,
      component: 'EditCustomGoalNewPlan',
    },
    {
      step: 4,
      component: 'EditCustomGoalPlanSuccess',
    },
  ];

  current_plan: CurrentPlanEntity = {
    target_amount: '',
    current_amount: '',
    monthly_required_amount: '',
    final_investment_date: '',
    associated_product_id: '',
    custom_goal_id: '',
    associated_product_name: '',
  }

  readonly initial_amount: InitialAmountEntity = {
    additional_amount: 0,
    there_is_more_savings: 'no',
  }

  define_by: DefineByEntity = {
    new_target_amount: 0,
    is_defined_by_contribution: false,
    by_time: {
      wants_years: false,
      months_to_reach_goal: 0,
      you_must_save: 0,
      accumulated_amount: 0,
    },
    by_contribution: {
      contribution: 0,
      months_to_reach_goal: 0,
      accumulated_amount: 0,
    },
  }

  current_step = 0;

  final_step = this.STEPS.length - 1;

  current_component = this.STEPS[this.current_step];

  is_loading = true;

  public constructor(view: Vue) {
    this.view = view;
  }

  initialize = (custom_investor_goal: CustomInvestorGoalEntity) => {
    this.is_loading = true;
    const { investor_goal, id } = custom_investor_goal;
    this.current_plan = {
      target_amount: this.getAmountFormatted(investor_goal.accumulated_amount),
      current_amount: this.getAmountFormatted(investor_goal.active_goal_amount || 0),
      monthly_required_amount: this.getAmountFormatted(investor_goal.monthly_required_amount),
      final_investment_date: this.date_formatter.formatDate(investor_goal.final_investment_date, 'DD/MM/YYYY'),
      associated_product_id: investor_goal.associated_product_id,
      custom_goal_id: id,
      associated_product_name: investor_goal.associated_product.name,
    };
    this.setCurrentPlantInformation();
    this.is_loading = false;
  }

  getAmountFormatted(amount: number) {
    return `${currencyFormat(amount)} MXN`;
  }

  setCurrentPlantInformation = () => {
    this.current_plan_service.setCurrentPlanInformation(this.current_plan);
  }

  setInitialAmountInformation = () => {
    this.initial_amount_service.setInitialAmountInformation(this.initial_amount);
  }

  setDefineByInformation = () => {
    this.define_by_service.setDefineByInformation(this.define_by);
  }

  clearStateInformation = () => {
    this.current_plan = {
      target_amount: '',
      current_amount: '',
      monthly_required_amount: '',
      final_investment_date: '',
      custom_goal_id: '',
      associated_product_id: '',
      associated_product_name: '',
    };
    this.setCurrentPlantInformation();
    this.setInitialAmountInformation();
    this.setDefineByInformation();
  }

  nextStep = () => {
    if (this.current_step === this.final_step) {
      this.endProcess();
      return;
    }

    if (this.current_step < this.final_step) {
      this.current_step += 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  prevStep = () => {
    if (this.current_step === 0) {
      this.endProcess();
      this.view.$emit('closeModal');
      return;
    }

    if (this.current_step > 0) {
      this.current_step -= 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  endProcess = () => {
    this.current_step = 0;
    this.current_component = this.STEPS[this.current_step];
  }
}
